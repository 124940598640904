<template>
  <div class="rephistory">
    <span
      class="rephistory__button"
      @click="showMore = !showMore"

      >
      {{$t('common.show_history')}}
      <img
      class="rephistory__button-img rephistory__button-img--showmore"
      :class="{'is-active': showMore}"
      src="/img/icons/report/arrow.svg" alt="Show more">
    </span>
    <div
      class="rephistory__body"
      v-show="showMore"
      >
      <ReportHistoryTable :history="history" />
    </div>
  </div>
</template>

<script>
import ReportHistoryTable from './ReportHistoryTable.vue';

export default {
  components: { ReportHistoryTable },
  name: 'ReportHistoryComponent',
  props: {
    history: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
};
</script>

<style lang="scss">
.rephistory {
  color:gray;
  text-align: right;
  &__button {
    cursor: pointer;
    &-img {
    width: 25px;
    height: auto;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    &--showmore {
      transition: transform 200ms ease;
      transform: rotate(-90deg);
      &.is-active {
        transform: rotate(90deg);
      }
    }
  }
  }
}
</style>
