<template>
  <div class="repphases">
    <div
      v-for="(phase, i) in phases"
      :key="i"
      class="repphases__phase"
      :class="{'is-active': phase === currentPhase}"
      @click="$emit('phaseClicked', phase)">

      <div class="repphases__phase-wrap">
        <span class="repphases__phase-pretitle">{{$t('common.phase')}}</span>
        <span class="repphases__phase-title">{{$t(`phases.${phase}`)}}</span>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportPhasesComponent',
  emits: ['phaseClicked'],
  props: {
    phases: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    currentPhase: {
      type: String,
      required: true,
      default: '1',
    },
  },
};
</script>

<style lang="scss">
.repphases {
  display: flex;
  margin-bottom: 40px;

  &__phase {
    flex: 0 0 calc(25% + 15px);
    background: var(--background-color-dark);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 10% 50%);
    margin-right: -20px;
    padding: 20px 0 20px 40px;
    box-sizing: border-box;
    cursor: pointer;

    &:first-child {
      clip-path: none;
      padding-left: 20px;
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    &.is-active {
      background: var(--background-color-inverted);
      .repphases__phase-pretitle {
        color: var(--text-color-lightest);
      }
      .repphases__phase-title {
        color: var(--text-color-inverted);
      }
    }
  }
  &__phase-wrap {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.1;
  }
  &__phase-pretitle {
    display: block;
    font-size: 12px;
    color: var(--text-color-lighter);
  }
  &__phase-pretitle {
    display: block;
    font-size: 14px;
  }
}
</style>
