<template>
  <Table
    :rows="history"
    :columns="columns"
    :rowactions="rowactions"
    :filters="filters"
    :classes="['table--challenge']"
  ></Table>
</template>

<script>

export default {
  name: 'ReportHistoryTable',
  props: {
    history: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      columns: [{
        head: this.$t('common.date'),
        value: (item) => {
          const date = new Date(item.date);
          const minutes = date.getMinutes() < 10 ? '0'.concat(date.getMinutes()) : date.getMinutes();
          return `${date.getDay()}/${date.getMonth()}/${date.getFullYear()} ${date.getHours()}:${minutes}`;
        },
      }, {
        head: this.$t('common.user'),
        value: (item) => item.user,
      }],
      filters: [],
      rowactions: [],
    };
  },
};
</script>
