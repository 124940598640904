<template>
  <div
    v-if="$t(`challenges_short.${entityType}.${challenge.name}`) !== ''"
    class="repcha"
    :class="{'is-optional': !challenge.is_required}"
    >

    <div v-if="!challenge.is_required" class="optional-label">
      {{$t('common.complementaries')}}
    </div>

    <div class="repcha__checkbox">
      <InputCheckbox
        :name="challenge.name"
        v-model="isChecked">
      </InputCheckbox>
    </div>

    <div class="repcha__body">
      <h4>{{$t(`challenges.${challenge.name}`)}}</h4>
      <div
        class="repcha__desc"
        v-html="$t(`challenges_short.${entityType}.${challenge.name}`)"></div>
      <div
        class="repcha__subdesc"
        v-html="$t(`challenges_long.${entityType}.${challenge.name}`)"
        v-show="showMore && $t(`challenges_long.${entityType}.${challenge.name}`) !== ''"></div>
    </div>

    <div class="repcha__buttons">
      <img
        v-if="$t(`challenges_long.${entityType}.${challenge.name}`) !== ''"
        class="repcha__button-img repcha__button-img--showmore"
        @click="showMore = !showMore"
        :class="{'is-active': showMore}"
        src="/img/icons/report/arrow.svg" alt="Show more">
      <img
        v-if="challenge.isChecked"
        class="repcha__button-img repcha__button-img--edit"
        @click="isModalOpen = true"
        src="/img/icons/report/edit.svg" alt="Edit">
    </div>

    <teleport to="body">
      <ModalChallenge
        v-if="isModalOpen"
        :title="$t(`challenges.${challenge.name}`)"
        @close="onModalClose(false)">

        <!-- prioritary sdgs -->
        <div v-if="challenge.show_sdgs">
          {{$t(`common.select_prioritary_sdgs`)}}<br><br>
          <div class="repcha__sdgs" v-if="universeIsLoaded">
            <div
              v-for="(sdg, i) in sdgsList"
              :key="i"
              class="repcha__sdg"
              :class="{'is-selected': sdgIsSelected(sdg)}">
              <img
                :src="`/img/${$i18n.locale}/sdg/${sdg.code}.png`"
                :alt="$t(`sdg.${sdg.code}`)"
                :title="$t(`sdg_name.${sdg.code}`)"
                @click="onSelectSDG(sdg)">
            </div>
          </div>
        </div>
        <!-- end prioritary sdgs -->

        <!-- contacts -->
        <div class="repcha__contacts" v-if="universeIsLoaded && challenge.show_contacts">
          <InputContacts
            name="shares"
            v-model="challengeContacts"
          ></InputContacts>
        </div>
        <!-- end contacts -->

        <!-- shares -->
        <div class="repcha__shares" v-if="universeIsLoaded && challenge.show_shares">
          <InputShares
            name="shares"
            v-model="challengeShares"
          ></InputShares>
        </div>
        <!-- end shares -->

        <!-- indicators -->
        <div class="repcha__indicators" v-if="universeIsLoaded && challenge.show_indicators">
          <InputIndicators
            name="indicators"
            v-model="challengeIndicators"
          ></InputIndicators>
        </div>
        <!-- end indicators -->

        <!-- networks -->
        <div class="repcha__networks" v-if="universeIsLoaded && challenge.show_networks">
          <InputNetworks
            name="networks"
            v-model="challengeNetworks"></InputNetworks>
        </div>
        <!-- end networks -->

        <!-- certifications -->
        <div class="repcha__certifications" v-if="universeIsLoaded && challenge.show_certifications">
          <InputCertifications
            name="certifications"
            v-model="challengeCertifications"></InputCertifications>
        </div>
        <!-- end certifications -->

        <!-- goals -->
        <div class="repcha__goals" v-if="universeIsLoaded && challenge.show_goals">
          <InputGoals
            name="goals"
            v-model="challengeGoals"
            ></InputGoals>
        </div>
        <!-- end goals -->

        <!-- local goals -->
        <div class="repcha__localgoals" v-if="universeIsLoaded && challenge.show_local_goals">
          <InputLocalGoals
            name="local_goals"
            v-model="challengeLocalGoals"
            ></InputLocalGoals>
        </div>
        <!-- end local goals -->

        <div v-if="showChallengeInputFile || showChallengeInputUrl">
          <br>
          {{$t(`common.upload_evidence_body`)}}
          <br><br>

          <!-- challenge attachment -->
          <div v-if="showChallengeInputFile">
            <div v-if="challengeFile">
              <label class="input-block__label">{{$t('common.attachment')}}</label><br>
              <a class="input-block__file" :href="getChallengeUrl(challengeFile)" :download="getChallengeFileName(challengeFile)" target="_blank">
                <img src="/img/icons/report/file.svg" :alt="$t('common.attachment')">
                {{getChallengeFileName(challengeFile)}}
              </a>
              <span @click="removeFile" class="input-block__remove__text">&#215;</span>
            </div>
            <InputFile name="file" :label="getInputFileLabel()" v-model="challengeFile"></InputFile>
          </div>
          <!-- end challenge attachment -->

          <!-- challenge url -->
          <div v-if="showChallengeInputUrl">
            <InputText
              name="url"
              :label="$t('common.url')"
              v-model="challengeUrl"
              :validators="urlValidators">
              <span v-if="challengeUrl && !urlValidators.every((v) => v(challengeUrl))">
                {{$t('error.invalid_url')}}
              </span>
            </InputText>
          </div>
          <!-- end challenge url -->
        </div>

        <template #footer>
          <Button @click="isModalOpen = false">{{$t('common.cancel')}}</Button>
          <Button
            @click="onModalClose(true)"
            :classes="['btn--accent']">
            {{$t('common.accept')}}
          </Button>
        </template>

      </ModalChallenge>
    </teleport>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Check from '@/services/check/Check';

export default {
  name: 'ReportChallengeComponent',
  emits: [
    'challengeConfirmed',
    'challengeFiled',
    'challengeUrled',
    'challengeSdged',
    'challengeShared',
    'challengeContactsed',
    'challengeIndicatorsed',
    'challengeNetworksed',
    'challengeCertificationsed',
    'challengeGoalsed',
    'challengeLocalGoalsed',
  ],
  data() {
    return {
      isModalOpen: false,
      showMore: false,
      challengeFile: null,
      challengeUrl: null,
      prioritarySdgs: [],
      challengeShares: [],
      challengeContacts: [],
      challengeIndicators: [],
      challengeNetworks: [],
      challengeCertifications: [],
      challengeGoals: [],
      challengeLocalGoals: [],
      urlValidators: [Check.isValidUrl],
    };
  },
  props: {
    challenge: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    reportGoals: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    reportLocalGoals: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    reportShares: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    reportContacts: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    reportIndicators: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    reportNetworks: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    reportCertifications: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    entityType: {
      type: String,
      required: true,
      default: 'PRIC',
    },
  },
  mounted() {
    this.updateChallenge(this.challenge);
  },
  computed: {
    ...mapGetters(['universeIsLoaded', 'goals', 'networks']),
    sdgsList() {
      return this.goals.filter((sdg) => sdg.type === 'GOAL');
    },
    isChecked: {
      get() {
        return !!this.challenge.isChecked;
      },
      set(value) {
        if (value) {
          this.openModal();
        } else {
          this.$emit('challengeConfirmed', false);
        }
      },
    },
    showChallengeInputFile() {
      return (
        this.challenge.name !== 'sdg-page'
        && this.challenge.name !== 'sdg-portal'
      );
    },
    showChallengeInputUrl() {
      return (
        this.challenge.name !== 'sdg-report'
        && this.challenge.name !== 'local-report'
      );
    },
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    onModalClose(isConfirmed) {
      this.$emit('challengeConfirmed', !!isConfirmed);
      this.isModalOpen = false;
    },
    getChallengeFileName(challengeFile) {
      if (typeof challengeFile !== 'string') return challengeFile.name;
      const splittedName = challengeFile.split('/');
      return splittedName[splittedName.length - 1];
    },
    removeFile() {
      this.challengeFile = null;
    },
    getChallengeUrl(challengeFile) {
      if (typeof challengeFile !== 'string') return challengeFile.file;
      return challengeFile;
    },
    getInputFileLabel() {
      return !this.challengeFile ? this.$t('common.upload_evidence') : '';
    },
    onSelectSDG(sdg) {
      const idx = this.prioritarySdgs.findIndex((id) => id === sdg.id);
      if (idx > -1) {
        this.prioritarySdgs.splice(idx, 1);
      } else if (this.prioritarySdgs.length < 6) {
        this.prioritarySdgs.push(sdg.id);
      }
      this.$emit('challengeSdged', this.prioritarySdgs);
    },
    sdgIsSelected(sdg) {
      return this.prioritarySdgs.findIndex((id) => id === sdg.id) > -1;
    },
    updateChallenge(challenge) {
      if (challenge.url && challenge.url !== this.challengeUrl) {
        this.challengeUrl = challenge.url;
      }
      if (challenge.file && challenge.file !== this.challengeFile) {
        this.challengeFile = challenge.file;
      }
      if (challenge.show_sdgs) {
        this.prioritarySdgs = this.reportGoals
          .filter((g) => g.type === 'GOAL')
          .map((g) => +g.global_goal);
      }
      if (challenge.show_shares) {
        this.challengeShares = this.reportShares;
      }
      if (challenge.show_contacts) {
        this.challengeContacts = this.reportContacts;
      }
      if (challenge.show_indicators) {
        this.challengeIndicators = this.reportIndicators
          .map((ind) => ({
            indicator: ind.indicator.id,
            type: 'IND',
            source: '?',
          }));
      }
      if (challenge.show_networks) {
        this.challengeNetworks = this.reportNetworks;
      }
      if (challenge.show_certifications) {
        this.challengeCertifications = this.reportCertifications.map((c) => ({
          name: c.certification.name,
          url: c.certification.url,
          year: c.year,
        }));
      }
      if (challenge.show_goals) {
        this.challengeGoals = this.reportGoals
          .filter((g) => g.type === 'SUBGOAL');
      }
      if (challenge.show_local_goals) {
        this.challengeLocalGoals = this.reportLocalGoals;
      }
    },
  },
  watch: {
    challengeFile(val) {
      this.$emit('challengeFiled', val);
    },
    challengeUrl(val) {
      this.$emit('challengeUrled', val);
    },
    challengeShares(val) {
      this.$emit('challengeShared', val);
    },
    challengeContacts(val) {
      this.$emit('challengeContactsed', val);
    },
    challengeIndicators(val) {
      this.$emit('challengeIndicatorsed', val);
    },
    challengeNetworks(val) {
      this.$emit('challengeNetworksed', val);
    },
    challengeCertifications(val) {
      this.$emit('challengeCertificationsed', val);
    },
    prioritarySdgs(val) {
      this.$emit('challengeSdged', val);
    },
    challengeGoals(val) {
      this.$emit('challengeGoalsed', val);
    },
    challengeLocalGoals(val) {
      this.$emit('challengeLocalGoalsed', val);
    },
    reportGoals() {
      this.updateChallenge(this.challenge);
    },
  },
};
</script>

<style lang="scss">
.repcha {
  display: flex;
  position: relative;

  &.is-optional {
    border-top: 1px solid var(--primary-color-default);
    padding-top: 40px;
    margin-top: 20px;
    .optional-label {
      display: block;
    }
    ~ .is-optional {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
      .optional-label {
        display: none;
      }
    }
  }
  .optional-label {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    pointer-events: none;
    color: var(--primary-color-default);
  }

  &__checkbox {
    flex: 0 0 50px;
  }
  &__body {
    flex: 0 0 calc(100% - 80px);
    padding-top: 10px;
    margin-bottom: 20px;
  }
  &__desc {
  }
  &__subdesc {
    margin-top: 24px;
  }
  &__buttons {
    flex: 0 0 30px;
  }
  &__button-img {
    width: 25px;
    height: auto;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    &--showmore {
      transition: transform 200ms ease;
      transform: rotate(-90deg);
      &.is-active {
        transform: rotate(90deg);
      }
    }
  }

  &__sdgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 40px;
  }
  &__sdg {
    flex: 0 1 52px;
    img {
      width: 100%;
      height: auto;
      padding: 4px;
      box-sizing: border-box;
      cursor: pointer;
      transition: opacity 250ms ease;
      &:hover {
        opacity: 0.6;
      }
    }
    &.is-selected {
      outline: 2px solid var(--primary-color-default);
      outline-offset: -2px;
    }
  }
}
</style>
